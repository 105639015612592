import React, { Fragment, useEffect, useState } from "react";
import { ScrollView, Text, View } from "react-native";

import { DefaultButton } from "../../Buttons/DefaultButton";
import { SpecialtyCard } from "../../Cards/SpecialtyCard";

import { SpecialtyProps } from "../../../models/doc24/specialtyProps";

import Toast from "react-native-root-toast";

import { getSpecialtiesDoc24 } from "../../../services/api/doc24/getSpecialtiesDoc24";
import { BasicLoading } from "../../Loadings/BasicLoading";
import { styles } from "./styles";
import { useAuth } from "../../../hooks/useAuth";
import { differenceInYears } from "date-fns";


interface SelectDoc24SpecialtiesProps {
  selectedSpecialty: SpecialtyProps;
  setSelectedSpecialty: React.Dispatch<React.SetStateAction<SpecialtyProps>>;
  setIsSpecialtySelected: React.Dispatch<React.SetStateAction<boolean>>;
  blockedSpecialties?: number[];
  isRestricted?: boolean;
}

const SelectDoc24Specialties = (props: SelectDoc24SpecialtiesProps) => {
  const {
    selectedSpecialty,
    setSelectedSpecialty,
    setIsSpecialtySelected,
    isRestricted,
    blockedSpecialties,
  } = props;

  const { user, userToken } = useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [specialties, setSpecialties] = useState<SpecialtyProps[]>([]);

  useEffect(() => {
    const handleFetchSpecialties = async () => {
      setIsLoading(true);
      const response = await getSpecialtiesDoc24(
        {
          plan: user.plan,
          isRestricted: !!isRestricted,
        },
        userToken
      );

      setSpecialties(response?.specialties || []);
      if (response?.error) {
        Toast.show(response.message, {
          duration: Toast.durations.LONG,
        });
      }
      setIsLoading(false);
    };

    handleFetchSpecialties();
  }, []);

  const iconsSelector: any = {
    "3": require("../../../../assets/icons/specialties/Pediatria.png"),
    "4": require("../../../../assets/icons/specialties/ClinicoGeral.png"),
    "7": require("../../../../assets/icons/specialties/Cardiologia.png"),
    "14": require("../../../../assets/icons/specialties/Dermatologia.png"),
    "15": require("../../../../assets/icons/specialties/Endocrinologia.png"),
    "16": require("../../../../assets/icons/specialties/Gastroenterologia.png"),
    "18": require("../../../../assets/icons/specialties/Geriatria.png"),
    "19": require("../../../../assets/icons/specialties/Ginecologia.png"),
    "26": require("../../../../assets/icons/specialties/Neurologia.png"),
    "29": require("../../../../assets/icons/specialties/Otorrinolaringologia.png"),
    "32": require("../../../../assets/icons/specialties/Psiquiatria.png"),
    "33": require("../../../../assets/icons/specialties/Psicologia.png"),
    "34": require("../../../../assets/icons/specialties/Nutricao.png"),
    "38": require("../../../../assets/icons/specialties/Urologia.png"),
    "113": require("../../../../assets/icons/specialties/EndocrinologaPediatra.png"),
    "132": require("../../../../assets/icons/specialties/OrtopediaTraumatologia.png"),
    "179": require("../../../../assets/icons/specialties/MedicinadaFamilia.png"),
    "182": require("../../../../assets/icons/specialties/Neuropediatria.png"),
    "184": require("../../../../assets/icons/specialties/Nutricao.png"),
    "187": require("../../../../assets/icons/specialties/NutrologiaPediatrica.png"),
    "200": require("../../../../assets/icons/specialties/HomeopatiaPediatrica.png"),
    "337": require("../../../../assets/icons/specialties/NutrologiaAdulto.png"),
    "339": require("../../../../assets/icons/specialties/Homeopatia.png")
  };

  function handleSelectSpecialty() {
    if (selectedSpecialty.specialtyId) {
      return setIsSpecialtySelected(false);
    }
    Toast.show("Selecione uma especialidade", {
      duration: Toast.durations.LONG,
    });
  }

  return (
    <>
      <ScrollView style={styles.scrollView}>
        <Text style={styles.instructionText}>Selecione uma especialidade:</Text>
        <View style={styles.specialtiesWrapper}>
          {isLoading ? (
            <View style={styles.loadingWrapper}>
              <BasicLoading />
            </View>
          ) : (
            specialties?.map((specialty) => {
              const pediatricSpecialtyId = 3;
              const pediatricMaxAge = 14;

              if (
                specialty.specialtyId === pediatricSpecialtyId &&
                !!user.birthdate
              ) {
                const birthdate = new Date(user.birthdate);
                const currentDate = new Date();

                if (
                  differenceInYears(currentDate, birthdate) > pediatricMaxAge
                ) {
                  return (
                    <SpecialtyCard
                      key={specialty?.specialtyId}
                      handlePress={() => setSelectedSpecialty(specialty)}
                      icon={
                        iconsSelector[
                        specialty?.specialtyId?.toString()
                        ] ||
                        require("../../../../assets/icons/specialties/ClinicoGeral.png")
                      }
                      isSelected={
                        specialty?.specialtyId === selectedSpecialty.specialtyId
                      }
                      specialtyData={specialty}
                      disabled={true}
                      disabledMessage="Pediatria não é permitida para maiores de 14 anos!"
                    />
                  );
                }
              }

              return (
                <SpecialtyCard
                  key={specialty?.specialtyId}
                  handlePress={() => setSelectedSpecialty(specialty)}
                  icon={
                    iconsSelector[
                    specialty?.specialtyId?.toString()
                    ] ||
                    require("../../../../assets/icons/specialties/ClinicoGeral.png")
                  }
                  isSelected={
                    specialty?.specialtyId === selectedSpecialty.specialtyId
                  }
                  specialtyData={specialty}
                  disabled={
                    !!blockedSpecialties &&
                    blockedSpecialties?.indexOf(specialty.specialtyId) !== -1
                  }
                  disabledMessage="O limite de atendimentos nessa especialidade foi atingido!"
                />
              );
            })
          )}
        </View>
      </ScrollView>
      {!isLoading && (
        <View style={styles.buttonWrapper}>
          <DefaultButton
            text="Selecionar"
            onPress={handleSelectSpecialty}
            disabled={!selectedSpecialty.specialtyId}
            additionalStyles={{
              alignSelf: "center",
            }}
          />
        </View>
      )}
    </>
  );
};

export { SelectDoc24Specialties };
